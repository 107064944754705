<template>
    <div class="max-w-3xl mx-auto text-center">
        <div class="flex flex-col justify-center items-center gap-4">
            <h1 class="sm:title-2 title-6">
                Are you a residential cleaner looking for help to grow and scale your business?
            </h1>
            <p class="subtitle">
                Running your cleaning business is about to get easier and more affordable! Augwa simplifies
                everything from scheduling to payments, crew management, and customer reminders — so you can focus
                on what matters.
            </p>
            <p>
                Join our waitlist to be the first to access our solution that will help your business thrive. You'll
                even be given an exclusive launch offer just for signing up.
            </p>
            <div>
                <div
                    class="flex flex-col mx-auto my-4 max-w-72 py-2 px-2 sm:w-[40rem] border border-highlight/50 border-2 justify-between gap-2 bg-primary-400/80 rounded-l text-primary items-center">
                    <template v-if="submissionError">
                        <div class="relative rounded-md shadow-sm w-full bg-white p-2 text-danger strong">
                            Looks like we ran into a snag adding you to the list. Please try again, or send us an email
                            instead to <a href="mailto:hello@augwa.com?subject=Website Inquiry">hello@augwa.com</a>
                        </div>
                    </template>
                    <template v-else-if="!submitted">
                        <div class="relative rounded-md shadow-sm w-full">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input ref="formEmail" type="email" name="email" id="email"
                                class="block w-full rounded-md border-0 py-1.5 pl-10 text-neutral-900 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-highlight sm:text-sm sm:leading-6"
                                :class="[emailError ? 'bg-danger text-white placeholder:text-neutral-100' : null]"
                                placeholder="you@example.com" @input="handleEmailInput" />
                        </div>
                        <Button :disabled="submitting" class="w-full" @click="handleJoinWaitlist">Join the
                            waitlist</Button>
                    </template>

                    <template v-else>
                        <div class="relative rounded-md shadow-sm w-full text-white strong">
                            We've saved a spot for you on the list! We're looking forward to bringing you onboard!
                        </div>
                    </template>
                </div>
            </div>
            <div>Join the <strong class="strong">500+</strong> cleaners who have already signed up.</div>
            <div>
                Want to get in touch with us? Send us an email to <a
                    href="mailto:hello@augwa.com?subject=Website Inquiry">hello@augwa.com</a>
            </div>
        </div>

    </div>
</template>

<script lang="ts" setup>
import { EnvelopeIcon } from '@heroicons/vue/20/solid'

const handleEmailInput = () => {
    emailError.value = false
}

const handleJoinWaitlist = async () => {
    submitting.value = true
    emailError.value = false

    const emailAddress = formEmail.value?.value

    if (emailAddress == null) {
        emailError.value = true
        submitting.value = false

        return
    }

    try {
        const { error } = await useFetch('/api/waitlist', {
            method: 'post',
            body: {
                email: emailAddress
            }
        })

        if (error.value != null) {
            console.error('someerror', error.value)
            submitting.value = false
            submissionError.value = true

            return
        }

    } catch (error: any | Error) {

        console.error(error)
        submitting.value = false
        submissionError.value = true

        return
    }

    submitting.value = false
    submitted.value = true
}

const submitting = ref(false)
const submitted = ref(false)
const emailError = ref(false)
const submissionError = ref(false)
const formEmail = ref<HTMLInputElement>()
</script>