<template>
    <button type="button">
        <slot />
    </button>
</template>

<style lang="postcss" scoped>
@layer BaseComponents {
    button {
        @apply rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-highlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-highlight;
    }
}
</style>